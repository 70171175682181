import { DateString } from 'shared';

const KEY = 'last-completed-date';

export const getLastCompletedDate = () =>
  localStorage.getItem(KEY) as DateString | null;

export const setLastCompletedDate = (date: DateString) => {
  localStorage.setItem(KEY, date);
};

export const getHasPlayedBefore = () => Boolean(getLastCompletedDate());
