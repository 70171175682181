import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { isTruthy } from '@matt-tingen/util';
import { useLocalStorageValue } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import { identity, orderBy, range } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import {
  FiPauseCircle,
  FiPlayCircle,
  FiRotateCcw,
  FiRotateCw,
} from 'react-icons/fi';
import { IoMdShare } from 'react-icons/io';
import {
  formatDate,
  getBoardDate,
  getStrikeTimePenalty,
  ScorablePlay,
  scoreSolo,
  scoreSoloWord,
  ScoringMethodology,
} from 'shared';
import { useLocation } from 'wouter';
import { checkBacklog } from './checkBacklog';
import { ColorModeToggle } from './ColorModeToggle';
import { useThemeColor } from './colors';
import { compoundDice } from './compoundDice';
import { formatDuration } from './formatDuration';
import { formatTimeIncrement } from './formatTimeIncrement';
import { GameBoard } from './GameBoard';
import { useGoofiness } from './goofiness';
import { IncrementDisplay } from './IncrementDisplay';
import { isExemptMiss } from './isExemptMiss';
import { setLastCompletedDate } from './lastCompletedDate';
import { NextGame } from './NextGame';
import { PauseCover } from './PauseCover';
import { ShareData } from './share';
import { StrikeIndicator } from './StrikeIndicator';
import { trpc, trpcClient } from './trpc';
import { useNickname } from './useNickname';
import { USER_ID } from './userId';
import { useSecondClickConfirm } from './useSecondClickConfirm';
import { useTimer } from './useTimer';
import { useWindowBlur } from './useWindowBlur';
import { WordList, WordListItem } from './WordList';
import { getWordStatus, useWordStatusColors } from './wordStatus';

interface Props {
  board: string[];
  dictionary: Set<string>;
  initialTimeMs: number;
  incrementalTimeMs: number;
  minWordLength: number;
  scoringMethodology: ScoringMethodology;
  onShare: (data: Omit<ShareData, 'date'>) => void;
}

const PageContainer = styled('div')<{ isDone: boolean }>(({ isDone }) => ({
  height: ['100vh', '100svh'],
  paddingTop: ['1vh', '1svh'],
  paddingBottom: ['10vh', '3svh'],
  display: 'grid',
  gridRowGap: ['2vh', '2svh'],
  gridTemplateRows: isDone ? 'auto auto 1fr auto 360px' : 'auto 1fr auto 360px',
  gridTemplateColumns: '1fr 360px 1fr',
  '& > *': {
    gridColumn: 2,
  },
}));

const preventPullToRefreshStyles = (
  <Global
    styles={{
      ':root': {
        overflow: 'hidden',
        overscrollBehavior: 'contain',
      },
    }}
  />
);

export const Game = ({
  board,
  dictionary,
  initialTimeMs,
  incrementalTimeMs,
  minWordLength,
  scoringMethodology: methodology,
  onShare,
}: Props) => {
  const [userName] = useNickname();
  const boardString = useMemo(() => board.join(''), [board]);
  const dateString = useMemo(
    () => formatDate(getBoardDate(boardString)!),
    [boardString],
  );
  const key = (suffix: string) => `${boardString}-${suffix}`;
  const [missedWords, setMissedWords] = useLocalStorageValue<string[]>(
    key('missed'),
    [],
  );
  const [foundWords, setFoundWords] = useLocalStorageValue<string[]>(
    key('found'),
    [],
  );
  const [rotation, setRotation] = useLocalStorageValue(key('rotation'), 0);
  const [savedTimer, setSavedTimer] = useLocalStorageValue(
    key('timer'),
    initialTimeMs,
  );

  const [wordIndices, setWordIndices] = useState<number[]>([]);
  const foundWordsSet = useMemo(() => new Set(foundWords), [foundWords]);
  const wordListRef = useRef<HTMLOListElement>(null);
  const word = useMemo(
    () =>
      wordIndices
        .map((i) => {
          const char = board[i];

          return compoundDice[char] ?? char;
        })
        .join(''),
    [board, wordIndices],
  );
  const statusColors = useWordStatusColors();
  const disabledColor = useThemeColor('gray.400', 'gray.500');

  const scoredSolo = useMemo(
    () =>
      scoreSolo(methodology, {
        user: { id: USER_ID, displayName: userName },
        found: foundWords,
        missed: missedWords,
      }),
    [foundWords, methodology, missedWords, userName],
  );
  const {
    wordScores,
    play: { individualScore: score },
  } = scoredSolo;

  const scoreIncrements = useMemo(
    () => foundWords.map((w) => wordScores.get(w) ?? 0),
    [foundWords, wordScores],
  );
  const scoreIncrementDisplays = useMemo(
    () => scoreIncrements.map((inc) => `+${inc}`),
    [scoreIncrements],
  );

  const positiveTimeIncrements = useMemo(
    () =>
      scoreIncrements.map((s) => formatTimeIncrement(s * incrementalTimeMs)),
    [incrementalTimeMs, scoreIncrements],
  );
  const negativeTimeIncrements = useMemo(
    () =>
      range(0, missedWords.length)
        .map((i) => -getStrikeTimePenalty(methodology, i + 1))
        .filter(isTruthy)
        .map((value) => ({
          value,
          label: formatTimeIncrement(value),
        })),
    [methodology, missedWords.length],
  );

  const [timeRemaining, { isRunning, resume, pause, addTime, subtractTime }] =
    useTimer(
      savedTimer,
      async () => {
        wordListRef.current!.scroll({
          left: 0,
          behavior: 'auto',
        });
        await scoreMutation.mutateAsync({
          board: boardString,
          score: {
            userId: USER_ID,
            score,
            found: foundWords,
            missed: missedWords,
          },
        });
      },
      true,
    );

  useWindowBlur(pause);

  const [isEndGameConfirmPending, endGameButtonProps] = useSecondClickConfirm(
    () => subtractTime(Infinity),
  );
  const displayTime = useMemo(
    () => formatDuration(timeRemaining, 'ceil'),
    [timeRemaining],
  );

  const isDone = !timeRemaining;
  const isPaused = !isRunning && !isDone;
  const wordStatus = getWordStatus(
    word,
    minWordLength,
    foundWordsSet,
    dictionary,
  );
  const isFound = foundWordsSet.has(word);
  const isMissed = missedWords.includes(word);
  const isValid = dictionary.has(word);

  const [, setLocation] = useLocation();
  const navigateToReview = useCallback(
    () => setLocation(`/review/${dateString}`),
    [dateString, setLocation],
  );

  const queryClient = useQueryClient();
  const scoreMutation = trpc.play.create.useMutation({
    onSuccess: (data, { board }) => {
      checkBacklog(true);

      queryClient.invalidateQueries({
        queryKey: trpc.play.list.getQueryKey(board),
      });
    },
    onMutate: async ({ board, score: { score: _, userId, ...score } }) => {
      setLastCompletedDate(dateString);
      const queryKey = trpc.play.list.getQueryKey(board);

      await queryClient.cancelQueries({ queryKey });
      queryClient.setQueryData<ScorablePlay[]>(queryKey, (prev) => [
        ...(prev ?? []),
        { ...score, user: { id: userId, displayName: userName } },
      ]);
    },
  });

  const foundWordsWithScore = useMemo(() => {
    const items = foundWords.map((word) => ({
      word,
      score: wordScores.get(word) ?? 0,
    }));

    return isDone
      ? orderBy(
          items,
          // Sorting by score and length is redundant since score is a function of
          // length, but this future proofs against changing the scoring method.
          ['score', 'length', identity],
          ['desc', 'desc', 'asc'],
        )
      : items;
  }, [foundWords, isDone, wordScores]);

  const goofiness = useGoofiness();

  useEffect(() => {
    setSavedTimer(timeRemaining);
  }, [setSavedTimer, timeRemaining]);

  const reset = useCallback(async () => {
    flushSync(() => {
      pause();
    });

    const allKeys = range(localStorage.length).map((i) => localStorage.key(i)!);
    const gameKeys = allKeys.filter((k) => k.startsWith(`${boardString}-`));

    gameKeys.forEach((k) => {
      localStorage.removeItem(k);
    });

    if (isDone) {
      try {
        await trpcClient.play.delete.mutate({
          userId: USER_ID,
          board: boardString,
        });
      } catch {
        // no-op
      }
    }

    setLocation('/');
    window.location.reload();
  }, [boardString, isDone, setLocation, pause]);

  useEffect(() => {
    const win = window as unknown as { reset?: () => void };

    win.reset = reset;

    return () => {
      delete win.reset;
    };
  }, [reset]);

  return (
    <>
      <PageContainer isDone={isDone}>
        <HStack justify="space-between" align="center">
          <div
            onDoubleClick={
              process.env.NODE_ENV === 'development' ? reset : undefined
            }
          >
            Score:{' '}
            <Box as="span" textAlign="right">
              {score}
              {!isDone && (
                <IncrementDisplay increments={scoreIncrementDisplays} />
              )}
            </Box>
          </div>
          <StrikeIndicator
            methodology={methodology}
            strikeCount={missedWords.length}
          />
          {isDone && (
            <IconButton
              size="xs"
              colorScheme="blue"
              icon={<IoMdShare />}
              aria-label="Share"
              onClick={() => {
                onShare({ score, words: foundWordsWithScore });
              }}
            />
          )}
          {isDone && (
            <Button
              size="xs"
              colorScheme="blue"
              onClick={() => {
                navigateToReview();
              }}
            >
              Leaderboard
            </Button>
          )}
          {!isDone && (
            <Button
              size="xs"
              colorScheme="blue"
              variant="outline"
              {...endGameButtonProps}
            >
              {isEndGameConfirmPending ? 'Confirm?' : 'End Game'}
            </Button>
          )}
          {!isDone && (
            <button
              type="button"
              onClick={() => (isPaused ? resume() : pause())}
            >
              <Stack direction="row" align="center" spacing={1}>
                <Box
                  minWidth={`${0.625 * displayTime.length}em`}
                  textAlign="right"
                >
                  {displayTime}
                  <IncrementDisplay increments={positiveTimeIncrements} />
                  <IncrementDisplay increments={negativeTimeIncrements} />
                </Box>
                {isPaused ? <FiPlayCircle /> : <FiPauseCircle />}
              </Stack>
            </button>
          )}
          <ColorModeToggle />
        </HStack>
        {isDone && (
          <Box margin="auto">
            <NextGame />
          </Box>
        )}
        <PauseCover isPaused={isPaused}>
          <WordList ref={wordListRef} isReadOnly={isDone} height="100%">
            {useMemo(
              () =>
                foundWordsWithScore.map(({ word, score }) => (
                  <WordListItem key={word}>
                    {word}{' '}
                    <Box as="span" color={disabledColor}>
                      {score}
                    </Box>
                  </WordListItem>
                )),
              [disabledColor, foundWordsWithScore],
            )}
          </WordList>
        </PauseCover>
        <HStack justify="space-between" align="center">
          <IconButton
            variant="ghost"
            size="lg"
            icon={<FiRotateCcw />}
            isDisabled={isPaused}
            aria-label="rotate board counterclockwise"
            onClick={() => setRotation((deg) => deg - 0.25)}
          />
          <Text
            color={isFound ? statusColors[wordStatus] : undefined}
            fontSize="3xl"
            overflow="visible"
          >
            {word}
          </Text>
          <IconButton
            variant="ghost"
            size="lg"
            icon={<FiRotateCw />}
            isDisabled={isPaused}
            aria-label="rotate board clockwise"
            onClick={() => setRotation((deg) => deg + 0.25)}
          />
        </HStack>
        <Center>
          <GameBoard
            board={board}
            rotation={rotation}
            goofiness={goofiness}
            isPaused={isPaused}
            onWordChange={setWordIndices}
            word={wordIndices}
            wordStatus={wordStatus}
            onWordEnd={() => {
              if (
                !isDone &&
                !isFound &&
                !isMissed &&
                word.length >= minWordLength
              ) {
                if (isValid) {
                  setFoundWords((prev) => [...prev, word]);
                  addTime(scoreSoloWord(methodology, word) * incrementalTimeMs);

                  setTimeout(() => {
                    const wordList = wordListRef.current;

                    if (wordList) {
                      const scrollLeftMax =
                        wordList.scrollWidth - wordList.clientWidth;

                      wordList.scroll({
                        left: scrollLeftMax,
                        behavior: 'smooth',
                      });
                    }
                  }, 0);
                } else if (!isExemptMiss(word)) {
                  subtractTime(
                    getStrikeTimePenalty(methodology, missedWords.length + 1),
                  );

                  setMissedWords((prev) => [...prev, word]);
                }
              }

              setWordIndices([]);
            }}
          />
        </Center>
      </PageContainer>
      {preventPullToRefreshStyles}
    </>
  );
};
