import { asDefault } from '@matt-tingen/util';
import { lazy } from 'react';
import { Page, PageContents, PageHeader } from './Page';

const PlayerStats = lazy(() =>
  asDefault(import('./PlayerStats'), 'PlayerStats'),
);

export const StatsPage = () => {
  return (
    <Page>
      <PageHeader>Stats</PageHeader>
      <PageContents>
        <PlayerStats />
      </PageContents>
    </Page>
  );
};
