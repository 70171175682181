import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'wouter';
import { ChangelogPage } from './ChangelogPage';
import { ChangesetCreatePage } from './ChangesetCreatePage';
import { DebugPage } from './DebugPage';
import { DictionaryPage } from './DictionaryPage';
import { FeatureFlagsPage } from './FeatureFlagsPage';
import { GamePage } from './GamePage';
import { HomePage } from './HomePage';
import { NotFoundPage } from './NotFoundPage';
import { PageSpinner } from './PageSpinner';
import { ReviewPage } from './ReviewPage';
import { StatsPage } from './StatsPage';

export const App = () => {
  return (
    <Suspense fallback={<PageSpinner />}>
      <Switch>
        <Route path="/" component={HomePage} />
        <Route path="/play">
          <Redirect to="/daily" replace />
        </Route>
        <Route path="/daily" component={GamePage} />
        <Route path="/review/:date/" component={ReviewPage} />
        <Route path="/stats" component={StatsPage} />
        <Route path="/dictionary" component={DictionaryPage} />
        <Route path="/changelog" component={ChangelogPage} />
        <Route path="/changeset" component={ChangesetCreatePage} />
        <Route path="/debug" component={DebugPage} />
        <Route path="/flags" component={FeatureFlagsPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};
